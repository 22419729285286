import React from 'react';

import Seo from '../../components/seo/Seo';
import JokairView from '../../views/JokairView';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';

export default function Jokair() {
  const { siteNavigation } = useSiteMetadata();

  return (
    <>
      <Seo
        currentTitle={siteNavigation.services[0].title}
        currentDescription={siteNavigation.services[0].description}
      />
      <JokairView />
    </>
  );
};