import React from 'react';

import {
  Security,
  ThumbUpAltRounded,
  DashboardCustomize,
  ElectricBoltRounded
} from '@mui/icons-material';

import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { ServiceTemplate } from '../components/templates/Service';

export default function JokairView() {
  const { siteNavigation } = useSiteMetadata();

  const ref = siteNavigation.services[0].ref;
  const name = siteNavigation.services[0].title;

  const data = {
    bannerImages: [{
      filename: `${ref}1.jpg`,
      alt: `A view of the ${name} software on a laptop`,
    }, {
      filename: `${ref}2.jpg`,
      alt: 'A view of the world map with the continents connected by network',
    }, {
      filename: `${ref}3.jpg`,
      alt: 'A laptop screen with security text displayed',
    }],
    section1: {
      title: `${name}, le logiciel qui vous donne le sourire`,
      titleImage: {
        filename: `${ref}_titleImage1.png`,
        alt: `A view of the ${name} software on a laptop`,
      },
      subtitle1: `${name} c'est quoi ?`,
      slogan1: 'Un logiciel simple, modulable, adaptatif, un atout pour votre entreprise.',
      content1: `Nous vous proposons non pas un logiciel, mais VOTRE logiciel pour la gestion des flux d'informations. Base de données, suivi des alarmes, fiches techniques personnalisées, à vous de choisir le ou les modules dont vous avez besoin. Une demande spécifique, ${name} s'adapte. Nous développons aussi des modules sur mesure, selon vos attentes. Votre ${name} vous correspond.`,
      imagesContent: [{
        filename: `${ref}_imageContent1.jpg`,
        alt: 'A keyboard with a blue button called "easy"',
      }, {
        filename: `${ref}_imageContent2.jpg`,
        alt: `A view of the ${name} software on a laptop showing a check mark`,
      }, {
        filename: `${ref}_imageContent3.jpg`,
        alt: `A view of the ${name} software on a laptop showing a secure QR code`,
      }, {
        filename: `${ref}_imageContent4.jpg`,
        alt: `A view of the ${name} software on a laptop`,
      }],
      subtitle2: `Pourquoi utiliser ${name} ?`,
      slogan2: 'C\'est vous qui définissez sa valeur.',
      content2: `Le ${name} s'adapte à votre budget. Vous évitez ainsi des investissements importants et non essentiels. Vous payez uniquement pour ce que vous utilisez. ${name} s'adapte également aux différents outils et programmes informatiques avec lesquels vous travaillez déjà.`,
    },
    section2: {
      title: `${name}, l'atout qu'il vous faut`,
      titleImage: {
        filename: `${ref}_titleImage2.png`,
        alt: `A view of the ${name} software on a laptop`,
      },
      subtitle1: `Pourquoi choisir ${name} ?`,
      slogan1: 'Un avantage pour votre entreprise.',
      content1: `C'est LE partenaire idéal pour développer l'efficacité de votre équipe, améliorer le développement de votre société avec un outil qui évolue en même temps que les différentes technologies. À vous de décider quand vous allez jouer avec votre ${name}.`,
    },
    advantages: {
      list: [{
        icon: <ThumbUpAltRounded fontSize="large" />,
        content: 'Simple d\'utilisation',
      }, {
        icon: <Security fontSize="large" />,
        content: 'Sécurisé',
      }, {
        icon: <ElectricBoltRounded fontSize="large" />,
        content: 'Efficace',
      }, {
        icon: <DashboardCustomize fontSize="large" />,
        content: 'Personnalisable',
      }],
      isInfo: false,
      info: '',
    },
    pricing: {
      isPricing: false,
      subtitle: '',
      info: '',
      subscriptions: [{
        title: '',
        price: '',
        services: [],
      }],
    },
    servicesPage: {
      prev: {
        title: siteNavigation.services[2].title,
        url: siteNavigation.services[2].url,
      },
      current: {
        title: name,
      },
      next: {
        title: siteNavigation.services[1].title,
        url: siteNavigation.services[1].url,
      },
    },
  };

  return (
    <ServiceTemplate data={data} />
  );
};